import { Backdrop, Box, Fade, Modal, TextField } from "@mui/material";
import { Review } from "../types";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createOnChangeHandler, getOverRides } from "../lib/helpers";
import { StandardButton } from "./standardButton";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { DashboardContext } from "../Pages/dashboard";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: 'hidden'
};

export const EditReviewModal = ({
  review,
  isOpen,
  setIsOpen,
}: {
  review: Review;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) => {
  const { getToken } = useAuth();
  const { updateReviews } = useContext(DashboardContext);
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const handleClose = () => setIsOpen(false);
  const placeholderImage = '/NoVideoAvailable.png';
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);
  const [showAreYouSureMessage, setShowAreYouSureMessage] =
    useState<boolean>(false);
  const [videoElementExists, setVideoElementExists] = useState<boolean>(false);

  useEffect(() => {
    setVideoElementExists(isOpen)
  }, [isOpen]);

  const [reviewerName, setReviewerName] = useState<string>(review.reviewerName);
  const [reviewerEmail, setReviewerEmail] = useState<string>(
    review.reviewerEmail || ""
  );
  const [reviewerPhoneNumber, setReviewerPhoneNumber] = useState<string>(
    review.reviewerPhoneNumber || ""
  );
  const [customPrompt, setCustomPrompt] = useState<string>(
    review.customPrompt || ""
  );

  const onReviewNameUpdate = useMemo(
    () => createOnChangeHandler(setReviewerName),
    [setReviewerName]
  );
  const onReviewerEmailUpdate = useMemo(
    () => createOnChangeHandler(setReviewerEmail),
    [setReviewerEmail]
  );
  const onReviewerPhoneNumberUpdate = useMemo(
    () => createOnChangeHandler(setReviewerPhoneNumber),
    [setReviewerPhoneNumber]
  );
  const onCustomPromptUpdate = useMemo(
    () => createOnChangeHandler(setCustomPrompt),
    [setCustomPrompt]
  );

  const onSubmitEdit = useCallback(async () => {
    const token = await getToken();
    updateReviews(
      await datasource.editReview(
        review.hash,
        reviewerName,
        reviewerEmail,
        reviewerPhoneNumber,
        customPrompt,
        token as string
      )
    );
    handleClose();
  }, [
    handleClose,
    review,
    reviewerName,
    reviewerEmail,
    reviewerPhoneNumber,
    getToken,
    customPrompt
  ]);

  const deleteReview = useCallback(async () => {
    const token = await getToken();
    updateReviews(await datasource.deleteReview(review.hash, token as string));
    handleClose();
  }, [getToken, handleClose, datasource, review]);

  const goToConfirmation = useCallback(
    () => setShowAreYouSureMessage(true),
    [setShowAreYouSureMessage]
  );
  const goBackToReview = useCallback(
    () => setShowAreYouSureMessage(false),
    [setShowAreYouSureMessage]
  );

  useEffect(() => {
    const setToTrue = () => setVideoIsPlaying(true);
    const setToFalse =() => setVideoIsPlaying(false);
    videoPlayerRef.current?.removeEventListener('play', setToTrue);
    videoPlayerRef.current?.removeEventListener('pause', setToFalse);
    videoPlayerRef.current?.removeEventListener('complete', setToFalse);
    videoPlayerRef.current?.addEventListener('play', setToTrue);
    videoPlayerRef.current?.addEventListener('pause', setToFalse);
    videoPlayerRef.current?.addEventListener('complete', setToFalse);
    return () => {
      videoPlayerRef.current?.removeEventListener('play', setToTrue);
      videoPlayerRef.current?.removeEventListener('pause', setToFalse);
      videoPlayerRef.current?.removeEventListener('complete', setToFalse);
    }
  }, [videoElementExists]);

  const videoStyleOverrides = useMemo(() => {
    if (showAreYouSureMessage) {
      return {}
    }
    return getOverRides(videoIsPlaying, videoPlayerRef, true, 606);
  }, [videoIsPlaying, showAreYouSureMessage])

  const cardStyleOverrides = useMemo(() => {
    if (showAreYouSureMessage) {
      return {}
    }
    return getOverRides(videoIsPlaying, videoPlayerRef, false, 606);
  }, [videoIsPlaying, showAreYouSureMessage])


  const mainPage = (
    <>
      {review.videoUrl ? (
        <video src={review.videoUrl} className="card-image" style={videoStyleOverrides} ref={videoPlayerRef} controls />
      ) : (
        <img src={placeholderImage} alt="Thumbnail" className="card-image" />
      )}
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Name"
          variant="outlined"
          value={reviewerName}
          onChange={onReviewNameUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Email"
          variant="outlined"
          value={reviewerEmail}
          onChange={onReviewerEmailUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Phone Number"
          variant="outlined"
          value={reviewerPhoneNumber}
          onChange={onReviewerPhoneNumberUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Custom Prompt"
          variant="outlined"
          multiline
          value={customPrompt}
          onChange={onCustomPromptUpdate}
          rows={4}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <StandardButton onClick={handleClose} buttonText="Cancel" />
        <StandardButton onClick={goToConfirmation} buttonText="Delete Review" />
        <StandardButton onClick={onSubmitEdit} buttonText="Save Changes" />
      </div>
    </>
  );

  const areYouSurePage = (
    <>
      <div>
        <h2>Are you sure you want to delete this review?</h2>
        <h4>This cannot be undone</h4>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <StandardButton onClick={deleteReview} buttonText="Delete Review" />
          <StandardButton onClick={goBackToReview} buttonText="Cancel" />
        </div>
      </div>
    </>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={{...style, ...cardStyleOverrides}}>
            {showAreYouSureMessage ? areYouSurePage : mainPage}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
