import { useCallback, useContext, useMemo, useState } from "react";
import { SideBarItemData } from "./sidbar";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DashboardContext } from "../Pages/dashboard";
import { EditCampaginModal } from "./editCampaignModal";

export interface SideBarItemProps {
  sidebarItem: SideBarItemData;
  setActiveSidebarItem: (sidebarItem: SideBarItemData) => void;
}

export const SideBarItem = (props: SideBarItemProps) => {
  const {
    sidebarItem: { name, isActive, hash },
    setActiveSidebarItem,
  } = props;

  const {openSnackBar} = useContext(DashboardContext);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null);
  };

  const onClick = useCallback(() => {
    setActiveSidebarItem(props.sidebarItem);
  }, [setActiveSidebarItem, props.sidebarItem]);

  const classes = useMemo(() => {
    let classes = "sideBarItemContainer";
    if (isActive) {
      classes += " activeSidebarItem";
    }
    return classes;
  }, [isActive]);


  const onCopyPress = (event: React.MouseEvent<HTMLElement>) => {
    window.navigator.clipboard.writeText(window.location.origin + '/org/' + hash)
    openSnackBar();
    handleClose(event)
}

  const onEditPress = (event: React.MouseEvent<HTMLElement>) => {
    setOpenEditModal(true);
    handleClose(event)
  }

  return (
    <>
    <EditCampaginModal open={openEditModal} setOpen={setOpenEditModal} campaign={props.sidebarItem} />
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton>
        <ListItemText primary={name} />
        <ListItemIcon onClick={handleClick} sx={{ width: "1rem", minWidth: 0 }}>
          <MoreVertIcon />
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={onEditPress}>Edit Campaign Details</MenuItem>
            <MenuItem onClick={onCopyPress}>Copy Campaign Review Link</MenuItem>
          </Menu>
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
    </>
  );
};
