import { StandardButton } from "../../components/standardButton";
import useScreenDimensions from "../../hooks/useScreenDimensions";
import { RecorderInfoResponse } from "../../types";
import { useCallback, useContext } from "react";
import { RecorderPageContext } from "../../Pages/recorder";
import React from "react";
import { isIOS } from "react-device-detect";

export const CustomQuestionPage = ({
  reviewInfo,
}: {
  reviewInfo: RecorderInfoResponse;
}) => {
  const { width, height } = useScreenDimensions();

  const { setPageNumber, renderMobileLayout, handleIphoneVideo } = useContext(RecorderPageContext);

  const goToNextPage = useCallback(() => {
    if (renderMobileLayout && isIOS) {
      handleIphoneVideo();
    } else {
      setPageNumber(3);
    }
  }, [setPageNumber]);
  return (
      <div
        style={{
          flex: 1,
          height: height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          marginLeft: '2rem',
          marginRight: '2rem'
        }}
      >
        <div>
          <h2>In your review, please answer the following:</h2>
        </div>
        <div>
         {reviewInfo.customPrompt ?? `What has been your overall experience with ${reviewInfo.orgName}`}
        </div>
        <div>
          <StandardButton
            buttonText="Next"
            onClick={goToNextPage}
            customStyles={{ width: width * (renderMobileLayout ? 0.7 : 0.4), height: "5rem", fontSize: '3rem' }}
          />
        </div>
      </div>
  );
};
