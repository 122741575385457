import { useCallback, useEffect, useState } from "react"
import useScreenDimensions from "../hooks/useScreenDimensions";
import { Box, TextField } from "@mui/material";
import { StandardButton } from "../components/standardButton";
import { Organization } from "../types";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const style = {
    width: '33%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export const RegisterOrg = () => {
    const {height} = useScreenDimensions();
    const {getToken} = useAuth()
    const [orgName, setOrgName] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const nav = useNavigate();
    const updateOrgField = useCallback((e: any) => {
        setOrgName(e.target.value);
    }, [setOrgName]);

    const updateUserNameField = useCallback((e: any) => {
        setUserName(e.target.value);
    }, [setUserName]);

    const onSubmit = useCallback(async () => {
        const token = await getToken();
        const complete = await datasource.registUserAndOrg(orgName, userName, token as string)
        nav('/dashboard');
    }, [orgName, userName, getToken]);

    useEffect(() => {
        const checkIfOrgExists = async () => {
            const token = await getToken();
            const org = await datasource.getOrg(token as string);
            if (org.id) {
                nav('/dashboard')
            }
        }

        checkIfOrgExists();
    }, [])

    

    return (
        <div style={{height, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={style}>
                <h2>Welcome to Speaking Of Me</h2>
                <h4>Tell us a bit about yourself</h4>
                <TextField label='Your Name' variant="outlined" value={userName} onChange={updateUserNameField} />
                <TextField label="Organization Name" variant="outlined" value={orgName} onChange={updateOrgField}/>
                <div>
                <StandardButton buttonText="Save" onClick={onSubmit} />

                </div>
            </Box>
        </div>
    )
}