export const createOnChangeHandler = (setStateFunc: (newState: any) => void): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => {
    return (e) => {
        setStateFunc(e.target.value);
    }
};

export const getOverRides = (videoIsPlaying: boolean, videoPlayerRef: React.RefObject<HTMLVideoElement>, isVideo: boolean, standardHeight: number) => {
      if (videoIsPlaying && videoPlayerRef.current) {
        const currentActualWidth = videoPlayerRef.current?.offsetWidth;
        const videoSourceHeight = videoPlayerRef?.current?.videoHeight;
        const videoSourceWidth = videoPlayerRef?.current?.videoWidth;
        const heightToSet = currentActualWidth * videoSourceHeight / videoSourceWidth;
        if (heightToSet > standardHeight) {
          return {
            height: standardHeight,
            width: videoSourceWidth * standardHeight / videoSourceHeight
          }
        } else if (isVideo) {
          return {
            height: currentActualWidth * videoSourceHeight / videoSourceWidth
          }
        } else {
          return {
  
          }
        }     
      } else {
        return {}
      }
  }