import Webcam from "react-webcam";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { StandardButton } from "../components/standardButton";
import { RecorderPageContext } from "../Pages/recorder";

const mediaSettings = {
  facingMode: "user",
  width: { min: 640, ideal: 1280, max: 1920 },
  height: { min: 480, ideal: 720, max: 1080 },
};

const buttonCustomStyles: React.CSSProperties = {
  marginLeft: '1rem',
  marginRight: '1rem',
  fontSize: '2rem'
}

export const CustomWebCam = ({
  isCapturing,
  webcamRef,
  videoAndPlaybackSyles,
  pageNumber,
  recordedChunks,
}: {
  isCapturing: boolean;
  webcamRef: React.MutableRefObject<any>;
  videoAndPlaybackSyles: React.CSSProperties;
  pageNumber: number;
  recordedChunks: any[];
}) => {
  const { height, width } = useScreenDimensions();
  const { handleStart, handleStop, submitVideo } =
    useContext(RecorderPageContext);
  const [isWatching, setIsWatching] = useState<boolean>(false);
  const [recordingSequence, setRecordingSequence] =
    useState<boolean>(isCapturing);
  const [displayText, setDisplayText] = useState<string | null>(
    "Not Recording"
  );

  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const videoPlaybackRef = useRef<HTMLVideoElement | null>(null);

  const startRecordingClick = useCallback(() => {
    setIsWatching(false)
    setRecordingSequence(true);
    setDisplayText("3");
    timeoutRef.current = setTimeout(() => {
      setDisplayText("2");
      timeoutRef.current = setTimeout(() => {
        setDisplayText("1");
        timeoutRef.current = setTimeout(() => {
          setDisplayText(null);
          timeoutRef.current = null;
          handleStart();
        }, 1000);
      }, 1000);
    }, 1000);
  }, [handleStart, setRecordingSequence, setDisplayText, timeoutRef]);

  const handleWatchVideoPress = useCallback(() => {
    setIsWatching(true);
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      const url = URL.createObjectURL(blob);

      setTimeout(() => {
        if (videoPlaybackRef.current) {
          videoPlaybackRef.current.src = url;
        }
      }, 0);
    }
  }, [setIsWatching, recordedChunks, videoPlaybackRef]);

  const stopRecordingClick = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    handleStop();
    setRecordingSequence(false);
    setDisplayText("Not Recording");
  }, [handleStop, setRecordingSequence, setDisplayText, handleWatchVideoPress]);

  const buttons: JSX.Element[] = [];

  if (pageNumber > 2) {
    if (!isWatching && !recordingSequence && !recordedChunks.length) {
      buttons.push(
        <StandardButton
          buttonText="Start Recording"
          onClick={startRecordingClick}
          customStyles={buttonCustomStyles}
          key={"Start Recording"}
        />
      );
    }

    if (!isWatching && !recordingSequence && recordedChunks.length) {
      buttons.push(
        <StandardButton
          buttonText="Watch Video"
          onClick={handleWatchVideoPress}
          customStyles={buttonCustomStyles}
          key={"Watch Video"}
        />
      );
    }

    if (recordedChunks.length) {
      buttons.push(
        <StandardButton buttonText="Submit" onClick={submitVideo}  
        customStyles={buttonCustomStyles}
        key={"Submit"}
        />
      );
      buttons.push(
        <StandardButton
          buttonText="Record Again"
          onClick={startRecordingClick}
          customStyles={buttonCustomStyles}
          key={"Record Again"}
        />
      );
    }

    if (recordingSequence) {
      buttons.push(
        <StandardButton
          buttonText="Stop Recording"
          onClick={stopRecordingClick}
          customStyles={buttonCustomStyles}
          key={"Stop Recording"}
        />
      );
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {(displayText && !isWatching) ? (
        <div
          style={{
            position: "absolute",
            color: "white",
            background: "black",
            opacity: 0.7,
            width: "10rem",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <span>{displayText}</span>
        </div>
      ) : null}

      {isWatching ? (
        <video
          ref={videoPlaybackRef}
          controls={true}
          style={videoAndPlaybackSyles}
        />
      ) : (
        <Webcam
          audio={true}
          muted={true}
          ref={webcamRef}
          videoConstraints={mediaSettings}
          style={videoAndPlaybackSyles}
        />
      )}

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {buttons}
      </div>
    </div>
  );
};
