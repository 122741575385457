import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import { Campaign, Organization, Review } from "../types";
import { datasource } from "../datasource/datasource";
import { CampaignButton } from "../components/campaignButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import './dashboard.css';
import { SideBar, SideBarItemData } from "../components/sidbar";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { ReviewCard } from "../components/reviewCard";
import { DashBoardTopBar } from "../components/dashboardTopBar";
import { Drawer, Snackbar } from "@mui/material";
import { DashboardContentSection } from "../components/dashboardContentSection";
import { Loading } from "../recorderComponents/loading";

export const DashboardContext = createContext<
    {
        updateCurrentCampaigns: (currentCampaigns: Campaign[]) => void, 
        activeCampaign: Campaign | null,
        updateReviews: (reviews: Review[]) => void,
        openSnackBar: () => void;
    }
>(
    {
        updateCurrentCampaigns: (currentCampaigns: Campaign[]) => undefined, 
        activeCampaign: null,
        updateReviews: (reviews: Review[]) => undefined,
        openSnackBar: () => undefined
    })

export const Dashboard = () => {
    const nav = useNavigate();
    const {getToken, userId, isLoaded} = useAuth();

    const [currentCampaigns, setCurrentCampaigns] = useState<Campaign[]>([]);
    const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [organization, setOrganization] = useState<Organization>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isLoaded && userId) {
          nav('/dashboard')
        }
    
      }, [userId, isLoaded])

    const openSnackBar = useCallback(() => {
        setSnackBarOpen(true);
        setTimeout(() => setSnackBarOpen(false), 3000)
    }, [setSnackBarOpen]);
    
    const displayedReviews = useMemo(() => {
        return reviews.filter(el => el.campaignId === activeCampaign?.id);
    }, [reviews, activeCampaign]);

    const {height} = useScreenDimensions();

    useEffect(() => {
        const setup = async() => {
            const token = await getToken() as string;
            const campProm = datasource.getCurrentCampaigns(token).then(campaigns => {
                setCurrentCampaigns(campaigns);
                if (campaigns.length) {
                    setActiveCampaign(campaigns[0]);
                }
            });

            const reviewProm = datasource.getAllReviews(token).then(reviews => {
                setReviews(reviews)
            })

            const orgProm = datasource.getOrg(token).then(org => {
                setOrganization(org)
                if (!org.id) {
                    nav('/set-up');
                }
            })

            await Promise.all([campProm, reviewProm, orgProm])

            setIsLoading(false);
        }

        setup();
    }, []);

    const sideBarItems = useMemo(() => {
        return currentCampaigns.map(el => {
            return {
                ...el,
                isActive: el.id === activeCampaign?.id
            }
        })
    }, [currentCampaigns, activeCampaign]);

    const setActiveSidebarItem = useCallback((sidebarItem: SideBarItemData) => {
        const [selectedItem] = currentCampaigns.filter(el => el.id === sidebarItem.id);
        setActiveCampaign(selectedItem);
    }, [setActiveCampaign, currentCampaigns]);


    const updateCurrentCampaigns = useCallback((campaigns: Campaign[]) => {
        setCurrentCampaigns(campaigns);
        setActiveCampaign(campaigns[0]);
    }, [setCurrentCampaigns, setActiveCampaign]);

    const updateReviews = useCallback((reviews: Review[]) => {
        setReviews(reviews)
    }, [setReviews]);


    return (
        <DashboardContext.Provider value={{updateCurrentCampaigns, activeCampaign, updateReviews, openSnackBar}} >
        <Drawer
        anchor="bottom"
        open={isLoading}
        PaperProps={{ sx: { backgroundColor: "#FFF8F0" } }}
        transitionDuration={{ exit: 500, enter: 500 }}
      >
        <Loading
        />
      </Drawer>
        <div className='dashboardPage' style={{minHeight: height, width: '100%'}}>
            <SideBar setActiveItem={setActiveSidebarItem} sidebarItems={sideBarItems}/>
            <div className="mainPanelContainer">
                <DashBoardTopBar />
                <DashboardContentSection reviews={displayedReviews} />
            </div>
            <Snackbar
                open={snackBarOpen}
                message='Copied to Clipboard'
            />
            
        </div>
        </DashboardContext.Provider>
    )
}