import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './ReviewCard.css'; // Import the CSS file for styling
import { Snackbar } from '@mui/material';
import { DashboardContext } from '../Pages/dashboard';
import { StandardButton } from './standardButton';
import { datasource } from '../datasource/datasource';
import { useAuth } from '@clerk/clerk-react';
import { EditReviewModal } from './editReviewModal';
import { Review } from '../types';
import { getOverRides } from '../lib/helpers';
import posthog from 'posthog-js';


export const ReviewCard = ({ review }: {review: Review}) => {
  const {getToken} = useAuth();
  const aTagRef = useRef<HTMLAnchorElement>(null);
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const placeholderImage = '/NoVideoAvailable.png';
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);

  const openTheModal = useCallback(() => setModalOpen(true), [setModalOpen])

  const {openSnackBar} = useContext(DashboardContext);

  const onCopy = useCallback(() => {
    window.navigator.clipboard.writeText(window.location.origin + '/invite/' + review.hash).then(() => {
      openSnackBar()
    })
  }, [review, openSnackBar]);

  const download = useCallback(async () => {
    posthog.capture('download', {'hash': review.hash})
    const token = await getToken()
    const res = await datasource.getVideo(review.hash, token as string);
    const blob = await res.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    if (aTagRef.current) {
      aTagRef.current.href = blobUrl;
      aTagRef.current.download = `${review.reviewerName}-${review.hash}-SOM.mp4`
      aTagRef.current.click();
      window.URL.revokeObjectURL(blobUrl);
    }
  }, [review, aTagRef, getToken]);

  useEffect(() => {
    const setToTrue = () => setVideoIsPlaying(true);
    const setToFalse =() => setVideoIsPlaying(false);
    videoPlayerRef.current?.addEventListener('play', setToTrue);
    videoPlayerRef.current?.addEventListener('pause', setToFalse);
    videoPlayerRef.current?.addEventListener('complete', setToFalse);

    return () => {
      videoPlayerRef.current?.removeEventListener('play', setToTrue);
      videoPlayerRef.current?.removeEventListener('pause', setToFalse);
      videoPlayerRef.current?.removeEventListener('complete', setToFalse);
    }
  }, []);

  const videoStyleOverrides = useMemo(() => {
    const standardCardHeight = parseFloat(getComputedStyle(document.documentElement).fontSize) * 25;
    return getOverRides(videoIsPlaying, videoPlayerRef, true, standardCardHeight);
  }, [videoIsPlaying])

  const cardStyleOverrides = useMemo(() => {
    const standardCardHeight = parseFloat(getComputedStyle(document.documentElement).fontSize) * 25;
    return getOverRides(videoIsPlaying, videoPlayerRef, false, standardCardHeight);
  }, [videoIsPlaying])



  return (
    <>
    <EditReviewModal isOpen={modalOpen} setIsOpen={setModalOpen} review={review}  />
    <div className="card" style={{opacity: modalOpen ? 0 : 1, zIndex: videoIsPlaying ? 10 : 1, height: '25rem', ...cardStyleOverrides}}>
      <a ref={aTagRef} href='/' style={{display: 'none'}}/>
      {review.videoUrl ? <video ref={videoPlayerRef} src={review.videoUrl} className='card-image' controls style={videoStyleOverrides}/> : <img
        src={placeholderImage}
        alt="Thumbnail"
        className="card-image"
      />}
      <div className="card-content" onClick={openTheModal}>
        <h2 className="card-title">{review.reviewerName}</h2>
        <p className="card-status">Status: {review.status}</p>
        {review.videoUrl ? <StandardButton onClick={download} buttonText='Download Video' /> : null}
        <StandardButton onClick={onCopy} buttonText='Copy Link to Clipboard' />
      </div>
    </div>
    </>
    
  );
};