import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { TextField } from "@mui/material";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { DashboardContext } from "../Pages/dashboard";
import { StandardButton } from "./standardButton";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createOnChangeHandler } from "../lib/helpers";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFF8F0",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// export const NewCampaignModalContext = React.createContext<{handleOpen: () => void, handleClose: () => void}>({handleOpen: () => undefined, handleClose: () => undefined});

export const NewCampaginModal = ({open, setOpen}: {open:boolean, setOpen: (newState: boolean) => void}) => {
  const { updateCurrentCampaigns } = useContext(DashboardContext);
  const { getToken } = useAuth();
  const handleClose = () => setOpen(false);

  const [newCampaginName, setNewCampaignName] = useState<string>("");
  const [defaultPrompt, setDefaultPrompt] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setNewCampaignName("");
      setDefaultPrompt('');
    }
  }, [open]);

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) => {
      setNewCampaignName(e?.target?.value);
    },
    [setNewCampaignName]
  );

  const onDefaultPropmptUpdate = useMemo(() => createOnChangeHandler(setDefaultPrompt), [setDefaultPrompt]);

  const handleSubmit = useCallback(() => {
    const sub = async () => {
      const token = await getToken();
      const campaigns = await datasource.addNewCampaign(
        newCampaginName,
        defaultPrompt,
        token as string
      );
      updateCurrentCampaigns(campaigns);
    };
    sub();
    handleClose();
  }, [newCampaginName, handleClose, updateCurrentCampaigns]);

  return (
    // <NewCampaignModalContext.Provider value={{handleOpen, handleClose}}>
    <div style={{ marginTop: "2rem" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
            <h2>Create A Campaign</h2>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem', width: '100%'}}>
            <TextField
              id="outlined-basic"
              label="New Campaign Name"
              variant="outlined"
              value={newCampaginName}
              onChange={onChange}
            />
            <TextField 
              label='Default Prompt'
              variant="outlined"
              value={defaultPrompt}
              onChange={onDefaultPropmptUpdate}
              multiline
              rows={4}
            />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", width: '100%' }}>
              <StandardButton onClick={handleClose} buttonText="Cancel" />
              <StandardButton
                onClick={handleSubmit}
                buttonText="Save New Campaign"
              />
            </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
    // </NewCampaignModalContext.Provider>
  );
};
