import { StandardButton } from "../../components/standardButton";
import useScreenDimensions from "../../hooks/useScreenDimensions";
import { RecorderInfoResponse } from "../../types";
import { useCallback, useContext } from "react";
import { RecorderPageContext } from "../../Pages/recorder";

export const CameraInstructionPage = ({
  reviewInfo,
}: {
  reviewInfo: RecorderInfoResponse;
}) => {
  const { width, height } = useScreenDimensions();

  const { setPageNumber , renderMobileLayout} = useContext(RecorderPageContext);

  const goToNextPage = useCallback(() => setPageNumber(2), [setPageNumber]);
  return (
      <div
        style={{
          flex: 1,
          height: height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          marginLeft: '2rem',
          marginRight: '2rem'
        }}
      >
        <div>
          <h2>Welcome {reviewInfo.reviewerName}</h2>
          <h4>Thank you for reviewing {reviewInfo.orgName}</h4>
        </div>
        <div>
          <p>Please find a place where:</p>
          <ul>
            <li>There is minimum background noise</li>
            <li>Where your camera will remain steady</li>
            <li>Where your face is fully lit</li>
          </ul>
        </div>
        <div>
          <StandardButton
            buttonText="Next"
            onClick={goToNextPage}
            customStyles={{ width: width * (renderMobileLayout ? 0.7 : 0.4), height: "5rem", fontSize: '3rem' }}
          />
        </div>
      </div>
  );
};
