import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { TextField } from "@mui/material";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { DashboardContext } from "../Pages/dashboard";
import { StandardButton } from "./standardButton";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createOnChangeHandler } from "../lib/helpers";
import { Campaign } from "../types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFF8F0",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// export const NewCampaignModalContext = React.createContext<{handleOpen: () => void, handleClose: () => void}>({handleOpen: () => undefined, handleClose: () => undefined});

export const EditCampaginModal = ({open, setOpen, campaign}: {open: boolean, setOpen: (newState: boolean) => void, campaign: Campaign}) => {
  const { updateCurrentCampaigns } =
    useContext(DashboardContext);
  const { getToken } = useAuth();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [campaginName, setCampaignName] = useState<string>(
    campaign?.name ?? ""
  );
  const [defaultPrompt, setDefaultPrompt] = useState<string>(
    campaign?.defaultPrompt ?? ""
  );
  const [page1, setPage1] = useState<boolean>(true);

  useEffect(() => {
    if (!open) {
      setPage1(true);
    }
  }, [open]);

  useEffect(() => {
    setCampaignName(campaign?.name ?? "");
    setDefaultPrompt(campaign?.defaultPrompt ?? "");
  }, [campaign]);

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) => {
      setCampaignName(e?.target?.value);
    },
    [setCampaignName]
  );

  const onDefaultPropmptUpdate = useMemo(
    () => createOnChangeHandler(setDefaultPrompt),
    [setDefaultPrompt]
  );

  const handleEditCampaign = useCallback(() => {
    const sub = async () => {
      if (campaign?.hash) {
        const token = await getToken();
        const campaigns = await datasource.editCampaign(
          campaign.hash,
          campaginName,
          defaultPrompt,
          token as string
        );
        updateCurrentCampaigns(campaigns);
      }
    };
    sub();
    handleClose();
  }, [campaginName, handleClose, updateCurrentCampaigns, campaign]);

  const handleDeleteCampaign = useCallback(async () => {
    const token = await getToken();
    if (campaign?.hash) {
      updateCurrentCampaigns(
        await datasource.deleteCampaign(campaign.hash, token as string)
      );
      handleClose();
    }
  }, [datasource, campaign, getToken, handleClose]);

  const goToPageTwo = useCallback(() => setPage1(false), [setPage1]);

  const contentsOfPage1 = (
    <>
      <div>
        <h2>Edit Campaign</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "100%",
        }}
      >
        <TextField
          id="outlined-basic"
          label="New Campaign Name"
          variant="outlined"
          value={campaginName}
          onChange={onChange}
        />
        <TextField
          label="Default Prompt"
          variant="outlined"
          value={defaultPrompt}
          onChange={onDefaultPropmptUpdate}
          multiline
          rows={4}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <StandardButton onClick={handleClose} buttonText="Cancel" />
        <StandardButton onClick={goToPageTwo} buttonText="Delete Campaign" />
        <StandardButton
          onClick={handleEditCampaign}
          buttonText="Save Changes"
        />
      </div>
    </>
  );

  const contentsOfPageTwo = (
    <>
      <h2>Are you sure you want to delete this campaign</h2>
      <h4>This will delete all associated links and videos. This cannot be undone</h4>
      <StandardButton onClick={handleDeleteCampaign} buttonText="Permanently Delete Campaign" />
      <StandardButton onClick={handleClose} buttonText="Cancel" />
    </>
  )

  return (
    // <NewCampaignModalContext.Provider value={{handleOpen, handleClose}}>
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            > {
              page1 ? contentsOfPage1 : contentsOfPageTwo
            }</div>
          </Box>
        </Fade>
      </Modal>
    </div>
    // </NewCampaignModalContext.Provider>
  );
};
