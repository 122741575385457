import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { NewCampaginModal } from "./newCampaignModal";
import { SideBarItem } from "./sidebarItem";
import { Campaign } from "../types";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useCallback, useState } from "react";

export interface SideBarItemData extends Campaign {
    isActive: boolean,
}

export interface SideBarProps {
    sidebarItems: SideBarItemData[],
    setActiveItem: (selectedSidebarItem: SideBarItemData) => void
}

export const SideBar = (props: SideBarProps) => {
    const {sidebarItems, setActiveItem} = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);

    // const {height} = useScreenDimensions();

    return (
        <Drawer
        sx={{
          backgroundColor: '#7b8b99',
          width: '15%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '15%',
            boxSizing: 'border-box',
            backgroundColor: '#7b8b99',
            color: 'white'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div className="sidebarHeader">
                <h2>My Campaigns</h2>
        </div>
        <Divider />
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={openModal}>
                <ListItemText primary={'Add New Campaign'} />
                <ListItemIcon sx={{ width: "1rem", minWidth: 0 }}>
                    <AddCircleIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider />
            <div style={{height: 20}} />
            {sidebarItems.map(el => {
                return (
                    <SideBarItem sidebarItem={el} setActiveSidebarItem={setActiveItem} key={el.id}/>
                )
            })}
        </List>
            <NewCampaginModal open={modalOpen} setOpen={setModalOpen} />
      </Drawer>
    )
}