import { RecorderInfoResponse } from "../types";
import { useLocation, useParams } from "react-router-dom";
import { datasource } from "../datasource/datasource";
import { isMobile } from "react-device-detect";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { Loading } from "../recorderComponents/loading";
import { CameraInstructionPage } from "../recorderComponents/desktop/cameraInstructionPage";
import { CustomQuestionPage } from "../recorderComponents/desktop/customQuestionPage";
import { JsxElement } from "typescript";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Webcam from "react-webcam";
import { CustomWebCam } from "../recorderComponents/CustomWebcam";
import { StandardButton } from "../components/standardButton";
import { Drawer } from "@mui/material";
import posthog from "posthog-js";

interface RecorderPageParams {
  id: string;
}

const initialContextValueForTheSakeOfTypeing = {
  handleStart: () => undefined,
  handleStop: () => undefined,
  setPageNumber: (num: number) => undefined,
  submitVideo: () => undefined,
  handleIphoneVideo: () => undefined,
  renderMobileLayout: false
};

export const RecorderPageContext = createContext<{
  handleStart: () => void;
  handleStop: () => void;
  setPageNumber: (pageNumber: number) => void;
  submitVideo: () => void;
  handleIphoneVideo: () => void;
  renderMobileLayout: boolean
}>(initialContextValueForTheSakeOfTypeing);

export const RecorderPage = () => {
  // @ts-expect-error
  const param: RecorderPageParams = useParams();
  const videoInputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const isInvite = useMemo(() => {
    return location.pathname.includes("invite");
  }, [location]);

  const { width, height } = useScreenDimensions();

  const renderMobileLayout = useMemo(
    () => isMobile || width <= 768,
    [isMobile, width, height]
  );

  const [pageNumber, setPageNumber] = useState<number>(0);

  const [reviewInfo, setReviewInfo] = useState<RecorderInfoResponse>();

  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);

  useEffect(() => {
    const getVal = async () => {
      const reviewInfo = await datasource.getRecordData(param.id, isInvite);
      if (reviewInfo.orgId) {
        setReviewInfo(reviewInfo);
        setPageNumber(1);
      }
    };
    getVal();
  }, []);

  // Start Video Logic
  const videoAndPlaybackSyles: React.CSSProperties = {
    width: "80%",
    maxHeight: height * 0.8,
  };
  const webcamRef = useRef<any | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      console.log("has Data");
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStart = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/mp4",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, setRecordedChunks]);

  const handleStop = useCallback(() => {
    mediaRecorderRef?.current?.stop?.();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  // End Video Logic
  const submitVideo = useCallback(async (chunkArray?: never[]) => {
    posthog.capture('video upload started', {hash: param.id})
    const arrayToUse = Array.isArray(chunkArray) && chunkArray.length ? chunkArray : recordedChunks;
    console.log('array to use', arrayToUse, chunkArray, recordedChunks)
    if (arrayToUse.length) {
      const blob = new Blob(arrayToUse, {
        type: "video/mp4",
      });
      setUploadStarted(true);
      const res = await datasource.submitVideo(param.id, blob, isInvite);
      if (res.ok) {
        setUploadComplete(true);
      }
    }
  }, [recordedChunks, param, isInvite]);

  let pageContent: JSX.Element;

  if (renderMobileLayout) {
    switch (pageNumber) {
      case 0:
        pageContent = <Loading />;
        break;
      case 1:
        pageContent = (
          <CameraInstructionPage
            reviewInfo={reviewInfo as RecorderInfoResponse}
          />
        );
        break;
      case 2:
        pageContent = (
          <CustomQuestionPage reviewInfo={reviewInfo as RecorderInfoResponse} />
        );
        break;
    }
  } else {
    switch (pageNumber) {
      case 0:
        pageContent = <Loading />;
        break;
      case 1:
        pageContent = (
          <CameraInstructionPage
            reviewInfo={reviewInfo as RecorderInfoResponse}
          />
        );
        break;
      case 2:
        pageContent = (
          <CustomQuestionPage reviewInfo={reviewInfo as RecorderInfoResponse} />
        );
        break;
    }
  }

  const openLoadingScreen = useMemo(
    () => uploadStarted || pageNumber === 0,
    [uploadStarted, pageNumber]
  );

  const handleIphoneVideo = useCallback(() => {
    videoInputRef.current?.click();
  }, [videoInputRef.current]);

  const handleUploadChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      console.log('in the if')
      const arrayBuffer = await e.target.files?.[0]?.arrayBuffer()
      const blob = new Blob([arrayBuffer], { type: 'video/mp4' });
      // @ts-ignore
      setRecordedChunks([blob])
      // @ts-ignore
      submitVideo([blob]);
    }
  }, [setRecordedChunks, submitVideo])


  return (
    <RecorderPageContext.Provider
      value={{ handleStart, handleStop, setPageNumber, submitVideo, handleIphoneVideo, renderMobileLayout}}
    >
      <Drawer
        anchor="bottom"
        open={openLoadingScreen}
        PaperProps={{ sx: { backgroundColor: "#FFF8F0" } }}
        transitionDuration={{ exit: 500, enter: 500 }}
      >
        <Loading
          isComplete={uploadComplete}
          completeText="Upload complete. Thank you"
          loadingText={uploadStarted ? 'Uploading Video. Do not close this tab' : ''}
        />
      </Drawer>
      <input type="file" id="videoInput" accept="video/mp4" capture="user" style={{display: 'none'}} ref={videoInputRef} onChange={handleUploadChange}></input>
      <div>
        <div style={{ width, height, display: "flex" }}>
          {pageNumber < 3 && (
            <div
              style={{
                flex: 1,
                height: height,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {/* @ts-ignore */}
              {pageContent}
            </div>
          )}
          {!renderMobileLayout || pageNumber > 2 ? (
            <div style={{ flex: 1 }}>
              <CustomWebCam
                isCapturing={capturing}
                webcamRef={webcamRef}
                videoAndPlaybackSyles={videoAndPlaybackSyles}
                pageNumber={pageNumber}
                recordedChunks={recordedChunks}
              />
            </div>
          ) : null}
        </div>
      </div>
    </RecorderPageContext.Provider>
  );
};
