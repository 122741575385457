import { UserButton } from "@clerk/clerk-react";
import { NewReviewModal } from "./newReviewModal";
import { StandardButton } from "./standardButton";
import { useCallback, useContext } from "react";
import { DashboardContext } from "../Pages/dashboard";
import { useLocation } from "react-router-dom";
import { EditCampaginModal } from "./editCampaignModal";

export const DashBoardTopBar = () => {
  const { activeCampaign, openSnackBar } = useContext(DashboardContext);

  const onButtonPress = () => {
    window.navigator.clipboard.writeText(
      window.location.origin + "/org/" + activeCampaign?.hash
    );
    openSnackBar();
  };

  return (
    <div className="dashboardTopBar">
      <h1 style={{ marginLeft: "2rem", marginTop: "2rem" }}>
        {activeCampaign?.name}
      </h1>
      <div style={{ marginRight: "2rem" }}>
        <UserButton />
      </div>
    </div>
  );
};
