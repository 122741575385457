import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './Pages/login';
import { RecorderPage } from './Pages/recorder';
import { Dashboard } from './Pages/dashboard';
import { ClerkProvider } from '@clerk/clerk-react';
import { clerkPublishableKey, REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } from './env';
import { RegisterOrg } from './Pages/RegisterOrg';

import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: 'https://us.posthog.com'
}

function App() {
  useEffect(() => {
    document.title = 'Speaking of Me'
  }, []);
  return (
    <>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
    </link>
    <PostHogProvider 
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    <Router>
      <div>
      <ClerkProvider publishableKey={clerkPublishableKey} afterSignOutUrl="/">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Login />} />
          <Route path='/set-up' element={<RegisterOrg/>} />
          <Route path="/invite/:id" element={<RecorderPage />} />
          <Route path="/org/:id" element={<RecorderPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
        </Routes>
      </ClerkProvider>
      </div>
    </Router>
    </PostHogProvider>
    </>
  );
}

export default App;
