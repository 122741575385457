import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { createOnChangeHandler } from "../lib/helpers";
import { OutlinedInput, TextField } from "@mui/material";
import "./newReviewModal.css";
import { useAuth } from "@clerk/clerk-react";
import { DashboardContext } from "../Pages/dashboard";
import { datasource } from "../datasource/datasource";
import { StandardButton } from "./standardButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { Review } from "../types";
// import Typography from '@mui/material/Typography';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFF8F0",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-around",
};

const getNewestReviewHash = (reviews: Review[]): string => {
  let currentLargestId = 0;
  let currentHash = "";
  reviews.forEach((el) => {
    if (el.id > currentLargestId) {
      currentLargestId = el.id;
      currentHash = el.hash;
    }
  });
  return currentHash;
};

// export const ModalContext = React.createContext<{handleOpen: () => void, handleClose: () => void}>({handleOpen: () => undefined, handleClose: () => undefined});

export const NewReviewModal = ({open, setOpen}: {open: boolean, setOpen: (newState: boolean) => void}) => {
  const { getToken } = useAuth();
  const { activeCampaign, updateReviews, openSnackBar } =
    useContext(DashboardContext);
  const [pageOne, setPageOne] = useState<boolean>(true);
  const [reviewHash, setReviewHash] = useState<null | string>(null);
  const [hasBeenCopied, setHasBeenCopied] = useState<boolean>(false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const goToPageTwo = useCallback(() => setPageOne(false), [setPageOne]);

  const [reviewerName, setReviewerName] = useState<string>("");
  const [reviewerEmail, setReviewerEmail] = useState<string>("");
  const [reviewerPhoneNumber, setReviewerPhoneNumber] = useState<string>("");
  const [customPrompt, setCustomPrompt] = useState<string>("");

  useEffect(() => {
    if (!open) {
      setReviewerEmail("");
      setReviewerName("");
      setReviewerPhoneNumber("");
      setHasBeenCopied(false);
      setPageOne(true);
      setCustomPrompt("");
    }
  }, [open]);

  const onReviewNameUpdate = useMemo(
    () => createOnChangeHandler(setReviewerName),
    [setReviewerName]
  );
  const onReviewerEmailUpdate = useMemo(
    () => createOnChangeHandler(setReviewerEmail),
    [setReviewerEmail]
  );
  const onReviewerPhoneNumberUpdate = useMemo(
    () => createOnChangeHandler(setReviewerPhoneNumber),
    [setReviewerPhoneNumber]
  );
  const onCustomPromptUpdate = useMemo(
    () => createOnChangeHandler(setCustomPrompt),
    [setCustomPrompt]
  );

  const onSubmit = useCallback(async () => {
    const newReviews = await datasource.addNewReview(
      reviewerName,
      reviewerEmail,
      reviewerPhoneNumber,
      customPrompt,
      activeCampaign?.id as number,
      (await getToken()) as string
    );
    updateReviews(newReviews);
    setReviewHash(
      `${window.location.origin}/invite/${getNewestReviewHash(newReviews)}`
    );
    goToPageTwo();
  }, [
    handleClose,
    reviewerEmail,
    reviewerName,
    reviewerPhoneNumber,
    activeCampaign,
    updateReviews,
    goToPageTwo,
    customPrompt
  ]);

  const copyHash = useCallback(() => {
    window.navigator.clipboard.writeText(reviewHash as string);
    setHasBeenCopied(true);
    openSnackBar();
  }, [openSnackBar, setHasBeenCopied, reviewHash]);

  const blur = useCallback((e: any) => {
    e.target.blur();
  }, []);

  const pageTwo = (
    <>
      <h2>Review Link Generated</h2>
      <h4>
        Send it to your customer whenever you are ready. You can copy it now or
        later
      </h4>
      <OutlinedInput
        sx={{ cursor: "pointer" }}
        value={reviewHash}
        endAdornment={hasBeenCopied ? <CheckIcon /> : <ContentCopyIcon />}
        onClick={copyHash}
        onFocus={blur}
      />
    </>
  );

  const mainPage = (
    <>
      <h2>Add a new review</h2>
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Name"
          variant="outlined"
          value={reviewerName}
          onChange={onReviewNameUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Email"
          variant="outlined"
          value={reviewerEmail}
          onChange={onReviewerEmailUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Reviewer Phone Number"
          variant="outlined"
          value={reviewerPhoneNumber}
          onChange={onReviewerPhoneNumberUpdate}
        />
      </div>
      <div className="textFieldContainer">
        <TextField
          label="Custom Prompt"
          multiline
          rows={4}
          variant="outlined"
          value={customPrompt}
          onChange={onCustomPromptUpdate}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <StandardButton onClick={handleClose} buttonText="Cancel" />
        <StandardButton onClick={onSubmit} buttonText="Create Link" />
      </div>
    </>
  );

  return (
    // <ModalContext.Provider value={{handleOpen, handleClose}}>
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>{pageOne ? mainPage : pageTwo}</Box>
        </Fade>
      </Modal>
    </div>
    // </ModalContext.Provider>
  );
};
