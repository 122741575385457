import { CircularProgress } from "@mui/material";
import useScreenDimensions from "../hooks/useScreenDimensions"

export const Loading = ({isComplete, completeText, loadingText}: {isComplete?:boolean, completeText?: string, loadingText?: string}) => {
    const {width, height} = useScreenDimensions();

    return (
        <div style={{width, height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={'/logoForLoading.png'} />
            {!isComplete ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {loadingText?.length ? <div style={{marginRight: '1rem'}}>{loadingText}</div> : ''}
                <CircularProgress />
                </div>
                 : completeText}
        </div>
    )
}