import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './ReviewCard.css'; // Import the CSS file for styling
import { NewReviewModal } from './newReviewModal';


export const NewReviewCard = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const openModel = useCallback(() => setModalOpen(true), [setModalOpen])

  return (
    <>
    <NewReviewModal open={modalOpen} setOpen={setModalOpen} />

    <div onClick={openModel} className="card-for-new-review">
    <div className="plus-sign-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="320" height="200" viewBox="0 0 24 24">
  {/* <!-- Vertical line --> */}
  <line x1="12" y1="2" x2="12" y2="22" stroke="black" strokeWidth="2"/>
  {/* <!-- Horizontal line --> */}
  <line x1="2" y1="12" x2="22" y2="12" stroke="black" strokeWidth="2"/>
</svg>
    </div>
      <div className="content-for-button">
        <h2 className="card-title" style={{textAlign: 'center'}}>Create New Review Invite Link</h2>
      </div>
    </div>
    </>
    
  );
};