import { Campaign, RecorderInfoResponse, Review } from "../types";

const currentBackend = (process.env.NODE_ENV === 'development') ? 'http://localhost:4000' :'https://backend.speakingof.me'

console.log(process.env.NODE_ENV)

const makeNullIfLengthIsZero = (st: string | void) => (!st || st.length) ? st : null;

class Datasource {
    async internalGetFetch(url: string, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const data = res.json();
        return data;
    }

    async internalGetFetchNoJson(url: string, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return res;
    }

    async noAuthInternalGetFetch(url: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
        });

        const data = res.json();
        return data;
    }

    async internalPost(url: string, body: any, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body)
        });

        const data = res.json();
        return data;
    }

    async internalPut(url: string, body: any, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(body)
        });

        const data = res.json();
        return data;
    }

    async internalDelete(url: string, token: string, body?: any,): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: JSON.stringify(body ?? {})
        });

        const data = res.json();
        return data;
    }

    async getRecordData(hash: string, isInvite: boolean): Promise<RecorderInfoResponse> {
        const path = isInvite ? '/recorder/invite/' + hash : '/recorder/org/' + hash
        return this.noAuthInternalGetFetch(path)
    }

    async getCurrentCampaigns(token: string): Promise<Campaign[]> {
        return this.internalGetFetch('/campaign', token)
    }

    async getAllReviews(token: string): Promise<Review[]> {
        return this.internalGetFetch('/campaign/reviews', token)
    }

    async addNewCampaign(newCampaignName: string, defaultPrompt: string | void, token: string) {
        const body = {newCampaignName, defaultPrompt: makeNullIfLengthIsZero(defaultPrompt) };
        return this.internalPost('/campaign', body, token);
    }

    async editCampaign(campaignHash: string, campaignName: string, defaultPrompt: string | void, token: string) {
        console.log('in the datasource')
        const body = {campaignName, defaultPrompt: makeNullIfLengthIsZero(defaultPrompt) };
        return this.internalPut('/campaign/edit/' + campaignHash, body, token);
    }

    async deleteCampaign(campaignHash: string, token: string) {
        return this.internalDelete('/campaign/edit/' + campaignHash, token);
    }

    async addNewReview(reviewerName: string, reviewerEmail: string | null, reviewerPhoneNumber: string | null, customPrompt: string | null, campaignId: number, token: string) {
        if (reviewerEmail === '') {
            reviewerEmail = null;
        }
        if (reviewerPhoneNumber === '') {
            reviewerPhoneNumber = null;
        }

        if (customPrompt === '') {
            customPrompt = null;
        }

        const body = {
            reviewerName,
            reviewerEmail,
            reviewerPhoneNumber,
            campaignId,
            customPrompt
        }

        return this.internalPost('/campaign/reviews', body, token)
    }

    async submitVideo(param: string, video: Blob, isInvite: boolean) {
        const path = isInvite ? '/recorder/invite/' + param : '/recorder/org/' + param
        const formData = new FormData();
        formData.append('file', video, param + '.mp4')
        return fetch(currentBackend + path, {
            method: 'POST',
            body: formData
        })
    }

    async getVideo(hash: string, token: string) {
        const file = await this.internalGetFetchNoJson(`/campaign/review/${hash}/download`, token);
        return file
    }

    async getOrg(token: string) {
        return this.internalGetFetch('/org', token)
    }

    async registUserAndOrg(orgName: string, name: string, token: string) {
        return this.internalPost('/user/signup', {name, orgName}, token)
    }

    async editReview(hash: string, name: string, email: string | void, phoneNumber: string | void, customPrompt: string | void, token: string) {
        return this.internalPut('/campaign/review/' + hash, {reviewerName: name, reviewerEmail: makeNullIfLengthIsZero(email), reviewerPhoneNumber: makeNullIfLengthIsZero(phoneNumber), customPrompt: makeNullIfLengthIsZero(customPrompt)}, token)
    }

    async deleteReview(hash: string, token: string) {
        return this.internalDelete('/campaign/review/' + hash, token)
    }
}

export const datasource = new Datasource();